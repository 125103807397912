<template lang="pug">
Loading(:key="currentWork")
ArtistModal.modal--center.flex-center(
  ref="modal"
  v-if="isShowArtistModal"
  :artist="selectedArtist"
  :isShowArrows="currentWork.groupData.artists.length>1"
  @closeModal='isShowArtistModal = false'
  @toPrevArtist='toPrevArtist'
  @toNextArtist='toNextArtist'
  )
.maincontain.flex-center.artworkContainer(ref="container")
  //- .btn--back
    .btn__content 前往展覽
    img.btn__img(:src="require(`@/assets/images/banner-arrow.svg`)")
  .artworkBlock(v-if="dataIsReady")
    .row
      .col
        .guild.d-inline-flex.flex-row
          img(:src="require(`@/assets/images/artwork-arrow.svg`)" @click="linkToPage")
          .guild__title(@click="linkToPage") {{ $t('artwork.back') }}
          .guild__subtitle / {{ currentWork[`work_${localeLang}`].title }}
    #masonryRow.row(data-masonry='{"percentPosition": true }')
      .col-12.col-md-12.col-lg-6
        .swiper__info
          #info.swiper-container
            .swiper-wrapper
              .swiper-slide(v-for="photo in currentWork.photos")
                .swiper-slide-img
                  img(:src="$getPhotoUrl(photo)")
          .swiper-pagination
          .swiper__btn.swiper__btn--pre.flex-center
            img.swiper__btn__img(:src="require(`@/assets/images/banner-arrow.svg`)")
          .swiper__btn.swiper__btn--next.flex-center
            img.swiper__btn__img(:src="require(`@/assets/images/banner-arrow.svg`)")
      .col-12.col-lg-6
        .artwork__title {{ currentWork.work_zh.title }}
        .artwork__title(v-if="currentWork.work_zh.title !== currentWork.work_en.title") {{ currentWork.work_en.title }}
        .artwork__label
          .artwork__label__item(v-for="tag in currentWork.tags") {{ tag.name_zh }} {{ tag.name_en }}
        //- .artwork__info
          .infolabel.flex-center
            .infolabel__text 團隊
          .infocontent {{ currentWork.groupData.group_zh.name }}
        .artwork__info(v-if="currentWork.year")
          .infolabel.flex-center
            .infolabel__text {{ $t('artwork.year') }}
          .infocontent {{ currentWork.year }}
        .artwork__info(v-if="currentWork[`work_${localeLang}`].material")
          .infolabel.flex-center
            .infolabel__text {{ $t('artwork.media') }}
          .infocontent {{ currentWork[`work_${localeLang}`].material }}
        .artwork__info(v-if="currentWork[`work_${localeLang}`].dimension")
          .infolabel.flex-center
            .infolabel__text {{ $t('artwork.dimensions') }}
          .infocontent {{ currentWork[`work_${localeLang}`].dimension }}
        .artwork__info(v-if="currentWork[`work_${localeLang}`].note")
          .infolabel.flex-center
            .infolabel__text {{ $t('artwork.credit') }}
          .infocontent(v-html="currentWork[`work_${localeLang}`].note")
        .artwork__info(v-if="currentWork.groupData.artists.length > 0")
          .infolabel.flex-center.align-self-start
            .infolabel__text {{ $t('shared.artist') }}
          .infocontent.artwork__info--artist
            .artist(
              v-for="(artist,index) in currentWork.groupData.artists"
              :key="index"
              @click="isShowArtistModal = true; selectedArtist = artist"
              )
              .artist__img
                img(:src="$getPhotoUrl(artist.avatar)")
              .artist__info
                .artist_info__title {{ artist.artist_zh.name }}
                .artist_info__title.artist_info__title--en(v-if="artist.artist_zh.name !== artist.artist_en.name") {{ artist.artist_en.name }}
        .artwork__info.artwork__info--date(v-if="currentWork.hasSession")
          .infolabel.flex-center
            .infolabel__text {{ $t('artwork.time') }}
          .d-flex.flex-column
            .infocontent.infocontent--date(
              v-for="s in currentWork.sessions"
            ) {{ s.date }}（{{ s.weekday }}）{{ s.startTime }} - {{ s.endTime }}
      .col-12.col-lg-6
        .content(v-html="currentWork[`work_${localeLang}`].proposal")
      .col-12.col-lg-6.mt-5(v-if="currentWork.otherWorks && currentWork.otherWorks.length>0")
        .artwork__elses
          .artwork__else__title {{ $t('artwork.otherWorks') }}
          .row
            .col-6.col-lg-4(
              v-for="(work, index) in currentWork.otherWorks"
              :key="index"
              @click="toArtWork($getWorkSlug(work.work_en.title))"
            )
              .artBox
                .artBox__img
                  img.img-fluid(:src="$getPhotoUrl(work.photo_1)")
                .artBox__content
                  .artBox__title.text-truncate {{ work[`work_${localeLang}`].title }}
                  .artBox__subtitle {{ currentWork.groupData[`group_${localeLang}`].name }}
    .hr.d-none.d-lg-block
    .row
      .recommend(v-if="randomWorks.length>0")
        .recommend__title {{ $t('artwork.like') }}
        .swiper__recommend
          #recommend.swiper-container
            .swiper-wrapper
              .swiper-slide(v-for="(work, index) in randomWorks" :key="index")
                .artBox(@click="toArtWork(work.slug)" :data-slug="work.slug")
                  .artBox__img
                    img.img-fluid(:src="$getPhotoUrl(work.photo_1)" :data-slug="work.slug")
                  .artBox__content
                    .artBox__title.text-truncate {{ work.work_zh.title }}
                    .artBox__subtitle(v-if="work.work_zh.title !== work.work_en.title") {{ work.work_en.title }}
          .swiper__btn.swiper__Recommendbtn--pre.flex-center
            img.swiper__btn__img(:src="require(`@/assets/images/artwork-swiper-arrow.svg`)")
          .swiper__btn.swiper__Recommendbtn--next.flex-center
            img.swiper__btn__img(:src="require(`@/assets/images/artwork-swiper-arrow.svg`)")
</template>

<script>
import Loading from "@/component/Loading";
import { getWork } from "@/api/work";
import { getGroup } from "@/api/group";
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper";
import { Swiper } from "swiper";
import Masonry from "masonry-layout";
import { mapState } from "vuex";
import ArtistModal from "@/component/ArtistModal.vue";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/navigation/navigation.scss";
import moment from "moment";

SwiperCore.use([Autoplay, Pagination, Navigation]);

export default {
  name: "artwork",
  components: {
    ArtistModal,
    Loading
  },
  data() {
    return {
      isShowArtistModal: false,
      breakpoint: null,
      recommentSwiper: null,
      initRecommentSwiper: false,
      infoSwiper: null,
      selectedArtistId: null,
      selectedArtist: null,
      randomWorks: [],
      currentSlug: '',
      currentWork: {
        groupData: {
          group_zh: {
            name: "",
          },
          group_en: {
            name: "",
          },
          artists: []
        },
        work_zh: {
          name: "",
          material: "",
        },
        work_en: {
          name: "",
        },
        tags: [],
        photos: [],
      },
    };
  },
  mounted() {},
  created() {
    this.breakpoint = window.matchMedia("(max-width:991px)");
    this.breakpoint.addListener(this.breakpointChecker);
    document.addEventListener('click',function(e){
      if(e.target.className== 'artBox'){
        this.currentSlug = e.target.getAttribute("data-slug")
        this.toArtWork(vm.currentSlug);
      }
    })
    // console.log('recommentSwiper',this.recommentSwiper)
    if (this.worksFmaf) this.getCurrentWork() && this.getRandomWorks();
  },
  methods: {
    toPrevArtist(){
      const artistCount = this.currentWork.groupData.artists.length
      const currentArtistIdx = this.currentWork.groupData.artists.indexOf(this.selectedArtist)
      if(currentArtistIdx === 0) this.selectedArtist = this.currentWork.groupData.artists[artistCount-1];
      if(currentArtistIdx !== 0) this.selectedArtist = this.currentWork.groupData.artists[currentArtistIdx-1];
      this.$refs.modal.getArtistData(this.selectedArtist.artist_id);
    },
    toNextArtist(){
      const artistCount = this.currentWork.groupData.artists.length
      const currentArtistIdx = this.currentWork.groupData.artists.indexOf(this.selectedArtist)
      if(currentArtistIdx === artistCount-1) this.selectedArtist = this.currentWork.groupData.artists[0];
      if(currentArtistIdx !== artistCount-1) this.selectedArtist = this.currentWork.groupData.artists[currentArtistIdx+1];
      this.$refs.modal.getArtistData(this.selectedArtist.artist_id);
    },
    breakpointChecker: function () {
      if (this.breakpoint.matches === true) {
        if (this.initRecommentSwiper ==  true){
          this.recommentSwiper.loopDestroy();
          this.recommentSwiper.destroy(true, true);
          this.initRecommentSwiper = false;
        }
      } else if (this.breakpoint.matches === false) {
        // console.log('this.recommentSwiper',this.recommentSwiper)
          return this.initRandomSwiper();
      }
    },
    linkToPage() {
      let target = this.currentWork.location.name_en.includes("FUTURE") ? "/fvl" : "/fmaf"
      this.$router.push({ path: target });
    },
    async getCurrentWork() {
      const weekdaysMin = "日_一_二_三_四_五_六".split("_");
      const currentWork = this.worksFmaf.find(
        (w) => w.slug === this.$route.params.slug
      );
      if (!currentWork) return;
      const workData = await getWork(currentWork.work_id);
      let groupData = {
        group_zh:{
          name: ''
        },
        group_en:{
          name: ''
        },
        works: [
          {id: null}
        ]
      }
      if(workData.groups.length > 0) groupData = await getGroup(workData.groups[0].group_id);
      const year = moment(workData.year).utcOffset("+0800").format("YYYY");
      const sessions = workData.session.map((s) => {
        if (s.start !== "") {
          return {
            date: moment(s.start).utcOffset("+0800").format("MM/DD"),
            weekday: weekdaysMin[moment(s.start).weekday()],
            startTime: moment(s.start).utcOffset("+0800").format("HH:mm"),
            endTime: moment(s.end).utcOffset("+0800").format("HH:mm"),
          };
        } else {
          return {
            date: "",
            weekday: "",
            startTime: "",
            endTime: "",
          };
        }
      });
      let photos = [];
      workData.photo_1 ? photos.push(workData.photo_1) : null;
      workData.photo_2 ? photos.push(workData.photo_2) : null;
      workData.photo_3 ? photos.push(workData.photo_3) : null;
      workData.photo_4 ? photos.push(workData.photo_4) : null;
      workData.photo_5 ? photos.push(workData.photo_5) : null;

      workData.work_en.note = workData.work_en.note.replace('\n', '<br>')
      workData.work_zh.note = workData.work_zh.note.replace('\n', '<br>')

      this.currentWork = {
        ...workData,
        year,
        sessions,
        photos,
        groupData,
        otherWorks: groupData.works.filter(
          (w) => {
            // 有些 group.work 裡面的資料是 {id: null}
            if(!w.work_id) return false
            return w.work_id !== currentWork.work_id && w.work_zh.title
          }
        ),
        hasSession: sessions[0].date !== "",
      };
      let workType = this.currentWork.location.name_en.includes("FUTURE") ? "fvl" : "fmaf"
      this.$store.dispatch('updateCurrentWorkType', workType)

      setTimeout(() => {
        this.initWorkSwiper();
      }, 500);
    },
    async getRandomWorks() {
      const totalWorkCount = this.worksFmaf.length;
      const workCount = 5;
      let workIdxList = [];
      let workList = [];
      while (workIdxList.length < workCount) {
        let r = Math.floor(Math.random() * totalWorkCount);
        if (workIdxList.indexOf(r) === -1) workIdxList.push(r);
      }
      workIdxList.forEach((id) => {
        workList.push(this.worksFmaf[id]);
      });
      this.randomWorks = workList;
      setTimeout(() => {
        this.initRandomSwiper();
      }, 500);
    },
    initWorkSwiper() {
      this.infoSwiper = new Swiper("#info.swiper-container", {
        speed: 1000,
        pagination: {
          clickable: true,
          el: ".swiper-pagination",
        },
        navigation: {
          nextEl: ".swiper__btn--next",
          prevEl: ".swiper__btn--pre",
        },
      });
      setTimeout(() => {
        const row = document.getElementById("masonryRow");
        new Masonry(row, {
          percentPosition: true,
          horizontalOrder: true,
        });
      }, 500);
    },
    initRandomSwiper() {
      const vm = this;
      if(this.initRecommentSwiper == false){
        this.initRecommentSwiper = true;
        this.recommentSwiper = new Swiper("#recommend.swiper-container", {
          initialSlide: 0,
          slidesPerView: 3,
          loop: false,
          speed: 1000,
          spaceBetween: 28,
          observer: true,
          observeParents: true,
            pagination: '.swiper-pagination',

          navigation: {
            nextEl: ".swiper__Recommendbtn--next",
            prevEl: ".swiper__Recommendbtn--pre",
          },
        });
        setTimeout(() => {
          this.recommentSwiper.slideToLoop(3, 0, false);
          this.breakpointChecker();
        }, 500);
      }else{
        this.recommentSwiper.loopDestroy();
        this.recommentSwiper.loopCreate();
        this.recommentSwiper.update();
        this.recommentSwiper.slideToLoop(3, 0, false);
      }
    },
    toArtWork(slug) {
      this.$router.push({ path: `/artworks/${slug}`});
    },
  },
  watch: {
    dataIsReady() {
      // 直接進入頁面
      // console.log("data ready update");
      this.getCurrentWork();
      this.getRandomWorks();
    },
    slug() {
      this.getCurrentWork();
      this.getRandomWorks();
    },
  },
  computed: {
    ...mapState(["worksFmaf", "dataIsReady"]),
    slug() {
      return this.$route.params.slug;
    },
    localeLang() {
      return this.$i18n.locale;
    },
  },
};
</script>

<style lang="sass" scoped>
*
  font-family: 'Noto Sans TC', sans-serif

// .maincontain
//   min-height: none
// .artworkContainer
//   min-height: 100vh

.row
  margin: 0

.btn--back
  z-index: 10
  transition: 0.5s ease-out
  background: radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0.06) 0%, #000000 100%)
  width: 194px
  height: 194px
  position: fixed
  right: -110px
  bottom: 5px
  border-radius: 50%
  display: flex
  align-items: center
  cursor: pointer

  &:hover
    right: -70px
    background: #000
    +rwd-md
      right: -10px

  &:hover > .btn__img
    opacity: 1

  +rwd-md
    font-size: 12px
    width: 86px
    height: 86px
    right: -43px

.btn__content
  color: #fff
  font-weight: 700
  writing-mode: vertical-lr
  display: inline-block
  margin-left: 45px
  line-height: 15px
  +rwd-md
    margin-left: 17px

.btn__img
  transition: 0.5s ease-out
  display: inline-block
  width: 25px
  margin-left: 10px
  opacity: 0

.artworkBlock
  margin-top: 57px
  width: 100%
  max-width: 1240px
  padding: 30px 20px 0 20px

  +rwd-md
    margin-top: 63px
    padding: 30px 4px 0 4px


  &__row
    width: 100%

  &__item
    font-size: 0
    width: 50%
    display: inline-block
    vertical-align: top

    .content, .content > *
      font-size: 14px
      line-height: 25px

.guild
  margin-bottom: 30px
  +rwd-md
    margin-left: 25px
  img
    cursor: pointer
  &__title
    cursor: pointer
    font-weight: 700
    margin-right: 10px
    margin-left: 8px

  &__subtitle
    color: #656565
    font-family: "Noto Sans TC", Arial, Helvetica, sans-serif

.content
  font-family: "Noto Sans TC", Arial, Helvetica, sans-serif
  line-height: 1.8em
  letter-spacing: 0.05em
  +rwd-md
    margin-top: 16px

.artwork
  &__title
    font-size: 18px
    font-weight: bold
    font-family: "Noto Sans TC", Arial, Helvetica, sans-serif
    margin-bottom: 10px

.artwork__label
  margin-bottom: 10px

  &__item
    font-weight: 500
    background: rgba(0, 0, 0, 0.6)
    color: #fff
    border-radius: 4px
    font-size: 12px
    margin: 5px 8px 5px 0
    display: inline-block
    padding: 2px 3px

    &:hover
      background: rgba(0, 0, 0, 1)

.artwork__info
  display: flex
  align-items: center
  margin-bottom: 8px

  &--date
    align-items: start

.infolabel
  width: 100%
  max-width: 130px
  background-color: #F4F4F4
  padding: 15px 20px
  flex: 1 100%
  +rwd-md
    max-width: 98px
    padding: 15px 4px

  &__text
    font-size: 12px
    font-weight: 700

.infocontent
  margin-left: 10px
  flex: 1 100%
  font-size: 12px

  &--artist
    margin-bottom: -16px

  &--date
    padding: 15px 0

.artist
  display: flex
  align-items: center
  padding: 16px
  border: solid 1px #fff
  cursor: pointer
  margin-bottom: 16px
  +rwd-md
    margin-bottom: 8px
    padding: 5.5px 3.5px


  // &:hover
  //   border: solid 1px #000

  &__img
    position: relative
    width: 70px
    height: 70px
    overflow: hidden

    img
      width: 100%
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
      object-fit: cover

  &__info
    margin-left: 19px
    font-weight: 700
    font-size: 12px

    &--en
      font-family: "Podkova", "Noto Sans TC", Arial, Helvetica, sans-serif
      font-weight: 700

.artwork__else
  margin-top: 8px
  padding: 0 35px
  +rwd-md
    padding: 0 14px

  &__title
    font-size: 12px
    font-weight: 700
    text-align: center
    margin-top: 32px
    margin-bottom: 16px

.artBox
  margin-top: 16px
  cursor: pointer
  position: relative
  box-sizing: border-box
  +rwd-md
    margin-top: 12px
    margin-bottom: 16px

  &__img
    position: relative
    padding-top: 100%
    width: 100%
    overflow: hidden

    img
      width: 100%
      height: 100%
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
      object-fit: cover

  &__content
    position: absolute
    padding: 10px
    bottom: 0
    width: 100%
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.48) 0%, rgba(0, 0, 0, 0.16) 38.65%, rgba(0, 0, 0, 0) 86.29%)

  &__title
    max-width: 100%
    color: #fff
    font-size: 12px
    font-weight: 500

  &__subtitle
    color: #fff
    font-size: 12px
    font-weight: 500

.hr
  width: 100%
  height: 1px
  border-top: solid 1px #000
  margin: 138px 0 38px

.recommend
  padding: 0 15% 87px 15%
  +rwd-md
    padding: 16px 36px 87px 36px

  &__title
    font-weight: 700
    text-align: center
    margin-bottom: 8px
    +rwd-md
      margin-bottom: 0

.swiper__recommend
  position: relative

  .artBox__img
    padding-top: 84%

  .artBox__title
    font-size: 14px
    margin-bottom: 2px

.recommend__block
  position: relative

  .artBox__img
    cursor: pointer
    position: relative
    box-sizing: border-box
    background-repeat: no-repeat
    background-position: center
    background-size: cover

    img
      padding-top: 84%
      width: 100%

  .artBox__title
    font-size: 14px
    margin-bottom: 2px
</style>
