import request from "@/utils/request";

/**
 * Get all groups
 */
export function getGroups() {
  return request({
    url: "api/v1/groups",
    method: "GET",
  });
}

/**
 * Get one advisor
 * @param {string} group_id
 */
export function getGroup(group_id) {
  return request({
    url: `api/v1/groups/${group_id}`,
    method: "GET",
  });
}